import React, {useEffect, useState} from "react";
import Styles from "./purchase.module.scss";
import { icon_items } from "../../../pages/ResiDashboard/icon-item";
import Logo from "../../../assets/images/logo.svg";
import LemonLabsLogo from "../../../assets/images/logo.svg";
import SelectInput from "../../UI/SelectInput";
import checkCircle from "../../../assets/images/signIn/check-circle.png";
import AnimationButton from "../../UI/AnimationButton";
import AnimationButtonGo from "components/UI/AnimationButtonGo";

const Purchase = (props) => {
    const {membershipType, user, cookies} = props;
    const [loading, setLoading] = useState(false);
    const [selectedPlanPurchase, setSelectedPlanPurchase] = useState();
    const [priceResi, setPriceResi] = useState('');
    const [fromPrice, setFromPrice] = useState(0);
    const [gbPrice, setGbPrice] = useState(0);
    const [selectedAmount, setSelectedAmount] = useState('');
    const [selectedFeature, setSelectedFeature] = useState([]);
    const [lemonLabsStatus, setLemonLabsStatus] = useState('');

    const Logos = {
        "Private": LemonLabsLogo,
    }

    const plans_features = {
        Private: [
            "9 Million of IPs",
            "Private Dedicated Pool",
            "World Wide Location",
            "No Expiry",
        ],
    }
    
    const setProxyStatus = (proxyType, stockData) => {
        const setStatus = (status) => {
            switch (proxyType) {
                case 'Private':
                    setLemonLabsStatus(status);
                    break;
            }
        };
        
        setStatus(stockData[proxyType] === 'instock');
    };

    const calculatePriceResi = (selectedPlanPurchase, selectedAmount) => {
        let calculatedPriceResi = 0;
        
        const PricePerGB = {
            "1": 2.6,
            "2": 5,
            "5": 12.5,
            "10": 24,
            "20": 46,
            "50": 110,
            "100": 200,
            "250": 450,
            "500": 750,
            "1000": 1200
        };
        
        const selectedPlanPrice = PricePerGB[selectedAmount];
        
        setGbPrice(selectedPlanPrice / parseFloat(selectedAmount))
        
        setPriceResi(selectedPlanPrice);
    };

    const dataValues = [
        {label: ''},
        {label: 1},
        {label: 5},
        {label: 10},
        {label: 20},
        {label: 50},
        {label: 100},
        {label: 250},
        {label: 500},
        {label: 1000},
    ];

    const packetStreamDataValues = [
        {label: ''},
        {label: 10},
        {label: 50},
        {label: 100},
        {label: 200},
    ]

    const checkAllProxyStocks = () => {
        const accessToken = cookies.get('access_token');
        fetch('https://proxyapi.embargotickets.com/api/v1/checkStock', {
            method: "POST",
            body: JSON.stringify({
                "discord_token": accessToken,
            })
        })
            .then(response => response.json())
            .then(data => {
                const proxyTypes = ['LemonLabs', 'BrightData', 'LemonGreen', 'LemonRed', 'NetNut', 'IPRoyal', 'GeoSurf', 'LemonPrime', 'PacketStream'];
                proxyTypes.forEach(proxyType => {
                    setProxyStatus(proxyType, data);
                });

                setInitialPlan();

                
            })
            .catch(error => {
                console.error(error);
                alert('Error checking stock, please try again.');
            });
    };


    const setInitialPlan = () => {
        const plan = document.getElementById("plan").value;
        setSelectedPlanPurchase(plan);
        setFeatureResi(plan);
        setPriceResi("0");
    }

    useEffect(() => {
        setFromPrice(1.2)
        checkAllProxyStocks();
        props.setIsLoading(false);
    }, [])

    const handleResiPurchase = () => {
        if (selectedAmount === "") {
            alert("Please select Amount");
            return;
        }

        setLoading(true);  
        // Return a promise that represents the purchase process
        return new Promise((resolve, reject) => {
          const accessToken = cookies.get('access_token');
      
          if (!selectedPlanPurchase || !selectedAmount ) {
            alert("Error! You didn't fill out all fields.");
            setLoading(false); // Set loading to false since the purchase failed
            reject();
            return;
          }
      
          fetch('https://proxyapi.embargotickets.com/api/v1/createResidentialOrder', {
              method: "POST",
              body: JSON.stringify({
              "discord_token": accessToken, 
              "proxy_type": selectedPlanPurchase,
              "proxy_amount": selectedAmount,
              "membership_type": membershipType,
              "email": `${user.email}`
            })
          })
            .then(response => response.text())
            .then(data => {
              window.location.href = data;
              resolve();
            })
            .catch(error => {
              console.error(error);
              setLoading(false); // Set loading to false since the purchase failed
              reject();
            });
        });
      };

    const handleBilling = () => {
        const accessToken = cookies.get('access_token');
        fetch('https://proxyapi.embargotickets.com/api/v1/createCustomerPortalSession', {
            method: "POST",
            body: JSON.stringify({
                "discord_token": `${accessToken}`
            })
        })
            .then(response => response.json())
            .then(data => {
                window.location.href = data["portal_session_url"];
            })
    }

    const handlePlanChange = (event) => {
        setSelectedPlanPurchase(event.target.value);
        setSelectedAmount('');
        setPriceResi('');
        setFeatureResi(event.target.value);
        calculatePriceResi(event.target.value, selectedAmount);
    };

    const setFeatureResi = (selectedPlanPurchase) => {
        setSelectedFeature(plans_features[selectedPlanPurchase]);
    }

    const handleDateValue = (event) => {
        setSelectedAmount(event.target.value);
        calculatePriceResi(selectedPlanPurchase, event.target.value);
    }

    return (
        <div className={`purchase-container ${Styles.container}`}>
            <div className={Styles.brand}>
                {icon_items.purchase}
                <span>Purchase Plans</span>
            </div>

            <select
                id={"plan"}
                className={Styles.selectPlan}
                value={selectedPlanPurchase}
                onChange={event => {
                    handlePlanChange(event);
                }}
            >
                <option className='purchase-option' value='Private'>Private Residential Pool</option>
            </select>

            <div className={Styles.lab}>
                <span>{selectedPlanPurchase}</span>

                <div className={Styles.labList}>
                    <div className={Styles.labList}>
                        {selectedFeature.map((item, index) => (
                            <div className={Styles.labItem}>
                                <img src={checkCircle} alt="check-circle" />
                                <div className={`main-text ${Styles.moreItemText}`}>{item}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={Styles.from}>From</div>

                <div className={Styles.labInfo}>
                    <span>${fromPrice}/GB</span>

                    <AnimationButtonGo
                        width="142px"
                        height="32px"
                        background="var(--re-white-button-bg)"
                        color="var(--re-white-button-color)"
                        size="13px"
                        borderRadius="10px"
                        button="dark"
                        label="Learn More"
                        goTo="https://discord.gg/sJGYeb792n"
                        isNewTab = "_blank"
                    />
                </div>

                <img className={Styles.logo} src={Logos[selectedPlanPurchase]} />
            </div>

            <div className={Styles.amount}>
                <div className={Styles.title}>
                    {icon_items.data}

                    <span>Data Amount</span>
                </div>

                <SelectInput
                    background="var(--hover-bg-color)"
                    option={selectedPlanPurchase === "PacketStream" ? packetStreamDataValues : dataValues}
                    onSelectChange={handleDateValue}
                    isGB = {true}
                />

                <div className={Styles.dataLabel}>
                    <span>Total</span>

                    <span>Price per GB</span>
                </div>

                <div className={Styles.price}>
                    <span>${priceResi}</span>

                    <span>${gbPrice}</span>
                </div>

                <div className={Styles.button}>
                    <AnimationButton
                        width="calc((100% - 11px) / 2)"
                        height="42px"
                        background="var(--re-white-yellow-bg)"
                        color="#FFF"
                        size="16px"
                        borderRadius="12px"
                        button="yellow"
                        label={!loading? "Purchase" : "Loading..."}
                        handler={handleResiPurchase}
                    />

                    <AnimationButton
                        width="calc((100% - 11px) / 2)"
                        height="42px"
                        background="var(--re-black-yellow-bg)"
                        color="var(--font-color)"
                        size="16px"
                        borderRadius="12px"
                        button="special"
                        label="Billing"
                        handler={handleBilling}
                    />
                </div>
            </div>
        </div>     
    );
};

export default Purchase;
