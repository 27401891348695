export const icon_items = {
    purchase: (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 18C21.3137 18 24 15.3137 24 12C24 8.68629 21.3137 6 18 6C14.6863 6 12 8.68629 12 12C12 15.3137 14.6863 18 18 18Z" stroke="url(#paint0_linear_1_2)" stroke-width="3"/>
            <path d="M18 18C21.3137 18 24 15.3137 24 12C24 8.68629 21.3137 6 18 6C14.6863 6 12 8.68629 12 12C12 15.3137 14.6863 18 18 18Z" stroke="url(#paint1_linear_1_2)" stroke-width="3"/>
            <path d="M18 18C21.3137 18 24 15.3137 24 12C24 8.68629 21.3137 6 18 6C14.6863 6 12 8.68629 12 12C12 15.3137 14.6863 18 18 18Z" stroke="url(#paint2_linear_1_2)" stroke-width="3"/>
            <g filter="url(#filter0_i_1_2)">
                <path d="M5.31228 20.2526C5.6387 16.3356 5.80191 14.3771 7.0936 13.1885C8.38529 12 10.3506 12 14.2812 12H21.7188C25.6494 12 27.6147 12 28.9064 13.1885C30.1981 14.3771 30.3613 16.3356 30.6877 20.2526C31.0678 24.8135 31.2578 27.094 29.9209 28.547C28.5839 30 26.2955 30 21.7188 30H14.2812C9.70446 30 7.41609 30 6.07913 28.547C4.74217 27.094 4.93221 24.8135 5.31228 20.2526Z" fill="#F1F1F1" fill-opacity="0.1"/>
                <path d="M5.31228 20.2526C5.6387 16.3356 5.80191 14.3771 7.0936 13.1885C8.38529 12 10.3506 12 14.2812 12H21.7188C25.6494 12 27.6147 12 28.9064 13.1885C30.1981 14.3771 30.3613 16.3356 30.6877 20.2526C31.0678 24.8135 31.2578 27.094 29.9209 28.547C28.5839 30 26.2955 30 21.7188 30H14.2812C9.70446 30 7.41609 30 6.07913 28.547C4.74217 27.094 4.93221 24.8135 5.31228 20.2526Z" fill="url(#paint3_linear_1_2)" fill-opacity="0.2"/>
                <path d="M5.31228 20.2526C5.6387 16.3356 5.80191 14.3771 7.0936 13.1885C8.38529 12 10.3506 12 14.2812 12H21.7188C25.6494 12 27.6147 12 28.9064 13.1885C30.1981 14.3771 30.3613 16.3356 30.6877 20.2526C31.0678 24.8135 31.2578 27.094 29.9209 28.547C28.5839 30 26.2955 30 21.7188 30H14.2812C9.70446 30 7.41609 30 6.07913 28.547C4.74217 27.094 4.93221 24.8135 5.31228 20.2526Z" fill="black" fill-opacity="0.5"/>
                <path d="M7.19517 13.2989C7.19514 13.2989 7.1952 13.2989 7.19517 13.2989ZM7.19517 13.2989C6.58102 13.8641 6.22722 14.6172 5.9876 15.729C5.74706 16.8451 5.62531 18.3025 5.46177 20.265C5.27136 22.5499 5.13019 24.248 5.20159 25.5571C5.27276 26.8618 5.55429 27.7551 6.18951 28.4454M7.19517 13.2989L28.8058 13.2998M7.19517 13.2989C7.80939 12.7338 8.58918 12.4438 9.71713 12.2973M7.19517 13.2989L9.71713 12.2973M6.18951 28.4454C6.18967 28.4456 6.18934 28.4452 6.18951 28.4454ZM6.18951 28.4454C6.82468 29.1354 7.69183 29.4906 8.98582 29.6698C10.2844 29.8497 11.9884 29.85 14.2812 29.85H21.7188C24.0116 29.85 25.7156 29.8497 27.0142 29.6698C28.3072 29.4907 29.1736 29.1365 29.8086 28.4475C29.8092 28.4468 29.8099 28.4461 29.8105 28.4454M29.8105 28.4454C30.4457 27.7551 30.7272 26.8618 30.7984 25.5571C30.8698 24.248 30.7286 22.5499 30.5382 20.265C30.3747 18.3025 30.2529 16.8451 30.0124 15.729C29.773 14.6181 29.4191 13.8648 28.8058 13.2998M29.8105 28.4454L29.8132 28.4426L29.8144 28.4413C29.8148 28.4409 29.8132 28.4425 29.8105 28.4454ZM28.8058 13.2998C28.806 13.3 28.8056 13.2996 28.8058 13.2998ZM9.71713 12.2973C10.8493 12.1503 12.3118 12.15 14.2812 12.15M9.71713 12.2973L14.2812 12.15M14.2812 12.15H21.7188M21.7188 12.15C23.6882 12.15 25.1507 12.1503 26.2829 12.2973M21.7188 12.15L26.2829 12.2973M26.2829 12.2973C27.4108 12.4438 28.1906 12.7338 28.8048 13.2989L26.2829 12.2973ZM6.185 28.4407L6.18273 28.4385L6.18198 28.4378C6.18175 28.4375 6.1833 28.439 6.18578 28.4415C6.18551 28.4412 6.18525 28.441 6.185 28.4407Z" stroke="url(#paint4_linear_1_2)" stroke-opacity="0.1" stroke-width="0.3"/>
            </g>
            <g filter="url(#filter1_i_1_2)">
                <path d="M24 24H12C11.1716 24 10.5 24.6716 10.5 25.5C10.5 26.3284 11.1716 27 12 27H24C24.8284 27 25.5 26.3284 25.5 25.5C25.5 24.6716 24.8284 24 24 24Z" fill="white" fill-opacity="0.16"/>
            </g>
            <defs>
                <filter id="filter0_i_1_2" x="5.03277" y="12" width="25.9345" height="18.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_2"/>
                </filter>
                <filter id="filter1_i_1_2" x="10.5" y="24" width="15" height="3.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="0.25"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_2"/>
                </filter>
                <linearGradient id="paint0_linear_1_2" x1="11.125" y1="7.5" x2="27.4762" y2="9.77101" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FE82DB"/>
                    <stop offset="1" stopColor="#68E4FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1_2" x1="10.5" y1="12.005" x2="25.5" y2="12.005" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#18C6EB"/>
                    <stop offset="1" stopColor="#0A91B3"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1_2" x1="24.58" y1="19.0841" x2="8.72792" y2="1.66263" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5961DC"/>
                    <stop offset="0.3574" stopColor="#434DE7"/>
                    <stop offset="1" stopColor="#3F46B4"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1_2" x1="29.844" y1="29.5009" x2="12.8982" y2="1.56575" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5961DC"/>
                    <stop offset="0.3574" stopColor="#434DE7"/>
                    <stop offset="1" stopColor="#FC2A01"/>
                </linearGradient>
                <linearGradient id="paint4_linear_1_2" x1="5.34375" y1="12.8182" x2="31.5" y2="12.8182" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    data: (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 18C14.1495 18 16.5 15.6495 16.5 12.75C16.5 9.85051 14.1495 7.5 11.25 7.5C8.35051 7.5 6 9.85051 6 12.75C6 15.6495 8.35051 18 11.25 18Z" fill="url(#paint0_linear_1_88)"/>
            <path d="M11.25 18C14.1495 18 16.5 15.6495 16.5 12.75C16.5 9.85051 14.1495 7.5 11.25 7.5C8.35051 7.5 6 9.85051 6 12.75C6 15.6495 8.35051 18 11.25 18Z" fill="url(#paint1_linear_1_88)"/>
            <g filter="url(#filter0_i_1_88)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5023 15.0411C25.2785 11.5241 21.934 9 18 9C14.6628 9 11.7498 10.8164 10.1957 13.5145C10.0484 13.5049 9.89975 13.5 9.75 13.5C6.02208 13.5 3 16.5221 3 20.25C3 23.9779 6.02208 27 9.75 27C9.74976 27 9.75024 27 9.75 27H18H28.5C28.4999 27 28.5001 27 28.5 27C30.9852 26.9998 33 24.9852 33 22.5C33 20.5368 31.7428 18.8672 29.9895 18.2524C29.9965 18.1692 30 18.085 30 18C30 16.3431 28.6569 15 27 15C26.8304 15 26.6642 15.0141 26.5023 15.0411Z" fill="#F1F1F1" fill-opacity="0.1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5023 15.0411C25.2785 11.5241 21.934 9 18 9C14.6628 9 11.7498 10.8164 10.1957 13.5145C10.0484 13.5049 9.89975 13.5 9.75 13.5C6.02208 13.5 3 16.5221 3 20.25C3 23.9779 6.02208 27 9.75 27C9.74976 27 9.75024 27 9.75 27H18H28.5C28.4999 27 28.5001 27 28.5 27C30.9852 26.9998 33 24.9852 33 22.5C33 20.5368 31.7428 18.8672 29.9895 18.2524C29.9965 18.1692 30 18.085 30 18C30 16.3431 28.6569 15 27 15C26.8304 15 26.6642 15.0141 26.5023 15.0411Z" fill="url(#paint2_linear_1_88)" fill-opacity="0.2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5023 15.0411C25.2785 11.5241 21.934 9 18 9C14.6628 9 11.7498 10.8164 10.1957 13.5145C10.0484 13.5049 9.89975 13.5 9.75 13.5C6.02208 13.5 3 16.5221 3 20.25C3 23.9779 6.02208 27 9.75 27C9.74976 27 9.75024 27 9.75 27H18H28.5C28.4999 27 28.5001 27 28.5 27C30.9852 26.9998 33 24.9852 33 22.5C33 20.5368 31.7428 18.8672 29.9895 18.2524C29.9965 18.1692 30 18.085 30 18C30 16.3431 28.6569 15 27 15C26.8304 15 26.6642 15.0141 26.5023 15.0411Z" fill="black" fill-opacity="0.5"/>
                <g mask="url(#mask0_1_88)">
                    <path d="M26.5023 15.0411L26.219 15.1397L26.3021 15.3787L26.5517 15.337L26.5023 15.0411ZM10.1957 13.5145L10.1762 13.8138L10.3625 13.826L10.4556 13.6642L10.1957 13.5145ZM29.9895 18.2524L29.6906 18.2275L29.6713 18.4587L29.8903 18.5355L29.9895 18.2524ZM18 9.3C21.8024 9.3 25.0358 11.7395 26.219 15.1397L26.7856 14.9425C25.5211 11.3086 22.0656 8.7 18 8.7V9.3ZM10.4556 13.6642C11.9584 11.0553 14.7744 9.3 18 9.3V8.7C14.5511 8.7 11.5411 10.5775 9.93572 13.3647L10.4556 13.6642ZM9.75 13.8C9.89324 13.8 10.0353 13.8047 10.1762 13.8138L10.2152 13.2151C10.0614 13.2051 9.90627 13.2 9.75 13.2V13.8ZM3.3 20.25C3.3 16.6878 6.18776 13.8 9.75 13.8V13.2C5.85639 13.2 2.7 16.3564 2.7 20.25H3.3ZM9.75 26.7C6.18776 26.7 3.3 23.8122 3.3 20.25H2.7C2.7 24.1436 5.85639 27.3 9.75 27.3V26.7ZM18 26.7H9.75V27.3H18V26.7ZM28.5 26.7H18V27.3H28.5003L28.5 26.7ZM32.7 22.5C32.7 24.8195 30.8195 26.6998 28.5 26.7L28.5003 27.3C31.1511 27.2998 33.3 25.1508 33.3 22.5H32.7ZM29.8903 18.5355C31.527 19.1094 32.7 20.6681 32.7 22.5H33.3C33.3 20.4054 31.9585 18.6249 30.0888 17.9693L29.8903 18.5355ZM29.7 18C29.7 18.0767 29.6968 18.1525 29.6906 18.2275L30.2885 18.2773C30.2961 18.1858 30.3 18.0933 30.3 18H29.7ZM27 15.3C28.4912 15.3 29.7 16.5088 29.7 18H30.3C30.3 16.1775 28.8225 14.7 27 14.7V15.3ZM26.5517 15.337C26.6973 15.3127 26.8471 15.3 27 15.3V14.7C26.8138 14.7 26.631 14.7155 26.4529 14.7452L26.5517 15.337Z" fill="url(#paint3_linear_1_88)" fill-opacity="0.1"/>
                </g>
            </g>
            <defs>
                <filter id="filter0_i_1_88" x="3" y="9" width="30" height="18.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_88"/>
                </filter>
                <linearGradient id="paint0_linear_1_88" x1="6.4375" y1="9.6" x2="17.8834" y2="11.1897" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1_88" x1="15.856" y1="17.7088" x2="0.733671" y2="2.19061" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5961DC"/>
                    <stop offset="0.3574" stop-color="#434DE7"/>
                    <stop offset="1" stop-color="#3F46B4"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1_88" x1="31.16" y1="26.5009" x2="8.56067" y2="-12.1508" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.3574"/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1_88" x1="3.9375" y1="9.81818" x2="33" y2="9.81818" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1"/>
                </linearGradient>
            </defs>
        </svg>
    
    ),
    analytics: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.3332 25.3333H3.99983C3.26345 25.3333 2.6665 25.9303 2.6665 26.6666V28C2.6665 28.7363 3.26345 29.3333 3.99983 29.3333H29.3332C30.0695 29.3333 30.6665 28.7363 30.6665 28V26.6666C30.6665 25.9303 30.0695 25.3333 29.3332 25.3333Z" fill="url(#paint0_linear_1_13)"/>
            <path d="M29.3332 25.3333H3.99983C3.26345 25.3333 2.6665 25.9303 2.6665 26.6666V28C2.6665 28.7363 3.26345 29.3333 3.99983 29.3333H29.3332C30.0695 29.3333 30.6665 28.7363 30.6665 28V26.6666C30.6665 25.9303 30.0695 25.3333 29.3332 25.3333Z" fill="url(#paint1_linear_1_13)"/>
            <path d="M29.3332 25.3333H3.99983C3.26345 25.3333 2.6665 25.9303 2.6665 26.6666V28C2.6665 28.7363 3.26345 29.3333 3.99983 29.3333H29.3332C30.0695 29.3333 30.6665 28.7363 30.6665 28V26.6666C30.6665 25.9303 30.0695 25.3333 29.3332 25.3333Z" fill="url(#paint2_linear_1_13)"/>
            <g filter="url(#filter0_i_1_13)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3333 0C14.2287 0 13.3333 0.895431 13.3333 2V26C13.3333 27.1046 14.2287 28 15.3333 28H18C19.1045 28 20 27.1046 20 26V2C20 0.895431 19.1045 0 18 0H15.3333ZM22.8235 8.66667C22.8235 7.5621 23.719 6.66667 24.8235 6.66667H27.4902C28.5948 6.66667 29.4902 7.5621 29.4902 8.66667V26C29.4902 27.1046 28.5948 28 27.4902 28H24.8235C23.719 28 22.8235 27.1046 22.8235 26V8.66667ZM4 14C4 12.8954 4.89543 12 6 12H8.66667C9.77124 12 10.6667 12.8954 10.6667 14V26C10.6667 27.1046 9.77124 28 8.66667 28H6C4.89543 28 4 27.1046 4 26V14Z" fill="#F1F1F1" fill-opacity="0.1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3333 0C14.2287 0 13.3333 0.895431 13.3333 2V26C13.3333 27.1046 14.2287 28 15.3333 28H18C19.1045 28 20 27.1046 20 26V2C20 0.895431 19.1045 0 18 0H15.3333ZM22.8235 8.66667C22.8235 7.5621 23.719 6.66667 24.8235 6.66667H27.4902C28.5948 6.66667 29.4902 7.5621 29.4902 8.66667V26C29.4902 27.1046 28.5948 28 27.4902 28H24.8235C23.719 28 22.8235 27.1046 22.8235 26V8.66667ZM4 14C4 12.8954 4.89543 12 6 12H8.66667C9.77124 12 10.6667 12.8954 10.6667 14V26C10.6667 27.1046 9.77124 28 8.66667 28H6C4.89543 28 4 27.1046 4 26V14Z" fill="url(#paint3_linear_1_13)" fill-opacity="0.2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3333 0C14.2287 0 13.3333 0.895431 13.3333 2V26C13.3333 27.1046 14.2287 28 15.3333 28H18C19.1045 28 20 27.1046 20 26V2C20 0.895431 19.1045 0 18 0H15.3333ZM22.8235 8.66667C22.8235 7.5621 23.719 6.66667 24.8235 6.66667H27.4902C28.5948 6.66667 29.4902 7.5621 29.4902 8.66667V26C29.4902 27.1046 28.5948 28 27.4902 28H24.8235C23.719 28 22.8235 27.1046 22.8235 26V8.66667ZM4 14C4 12.8954 4.89543 12 6 12H8.66667C9.77124 12 10.6667 12.8954 10.6667 14V26C10.6667 27.1046 9.77124 28 8.66667 28H6C4.89543 28 4 27.1046 4 26V14Z" fill="black" fill-opacity="0.5"/>
                <g mask="url(#mask0_1_13)">
                    <path d="M13.6 2C13.6 1.04271 14.376 0.266671 15.3333 0.266671V-0.266663C14.0814 -0.266663 13.0666 0.748159 13.0666 2H13.6ZM13.6 26V2H13.0666V26H13.6ZM15.3333 27.7333C14.376 27.7333 13.6 26.9573 13.6 26H13.0666C13.0666 27.2518 14.0814 28.2667 15.3333 28.2667V27.7333ZM18 27.7333H15.3333V28.2667H18V27.7333ZM19.7333 26C19.7333 26.9573 18.9573 27.7333 18 27.7333V28.2667C19.2518 28.2667 20.2666 27.2518 20.2666 26H19.7333ZM19.7333 2V26H20.2666V2H19.7333ZM18 0.266671C18.9573 0.266671 19.7333 1.04271 19.7333 2H20.2666C20.2666 0.748159 19.2518 -0.266663 18 -0.266663V0.266671ZM15.3333 0.266671H18V-0.266663H15.3333V0.266671ZM24.8235 6.4C23.5717 6.4 22.5569 7.41482 22.5569 8.66667H23.0902C23.0902 7.70937 23.8662 6.93333 24.8235 6.93333V6.4ZM27.4902 6.4H24.8235V6.93333H27.4902V6.4ZM29.7569 8.66667C29.7569 7.41482 28.742 6.4 27.4902 6.4V6.93333C28.4475 6.93333 29.2235 7.70937 29.2235 8.66667H29.7569ZM29.7569 26V8.66667H29.2235V26H29.7569ZM27.4902 28.2667C28.742 28.2667 29.7569 27.2518 29.7569 26H29.2235C29.2235 26.9573 28.4475 27.7333 27.4902 27.7333V28.2667ZM24.8235 28.2667H27.4902V27.7333H24.8235V28.2667ZM22.5569 26C22.5569 27.2518 23.5717 28.2667 24.8235 28.2667V27.7333C23.8662 27.7333 23.0902 26.9573 23.0902 26H22.5569ZM22.5569 8.66667V26H23.0902V8.66667H22.5569ZM6 11.7333C4.74815 11.7333 3.73333 12.7482 3.73333 14H4.26667C4.26667 13.0427 5.04271 12.2667 6 12.2667V11.7333ZM8.66667 11.7333H6V12.2667H8.66667V11.7333ZM10.9333 14C10.9333 12.7482 9.91851 11.7333 8.66667 11.7333V12.2667C9.62396 12.2667 10.4 13.0427 10.4 14H10.9333ZM10.9333 26V14H10.4V26H10.9333ZM8.66667 28.2667C9.91851 28.2667 10.9333 27.2518 10.9333 26H10.4C10.4 26.9573 9.62396 27.7333 8.66667 27.7333V28.2667ZM6 28.2667H8.66667V27.7333H6V28.2667ZM3.73333 26C3.73333 27.2518 4.74815 28.2667 6 28.2667V27.7333C5.04271 27.7333 4.26667 26.9573 4.26667 26H3.73333ZM3.73333 14V26H4.26667V14H3.73333Z" fill="url(#paint4_linear_1_13)" fill-opacity="0.1"/>
                </g>
            </g>
            <defs>
                <filter id="filter0_i_1_13" x="4" y="0" width="25.4902" height="28.6667" filterUnits="userSpaceOnUse" >
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.666667"/>
                    <feGaussianBlur stdDeviation="1.33333"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_13"/>
                </filter>
                <linearGradient id="paint0_linear_1_13" x1="3.83317" y1="26.1333" x2="19.8268" y2="41.6827" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FE82DB"/>
                    <stop offset="1" stopColor="#68E4FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1_13" x1="2.6665" y1="27.3347" x2="30.6665" y2="27.3347" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#18C6EB"/>
                    <stop offset="1" stopColor="#0A91B3"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1_13" x1="28.9492" y1="29.2224" x2="27.8636" y2="20.8714" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5961DC"/>
                    <stop offset="0.3574" stopColor="#434DE7"/>
                    <stop offset="1" stopColor="#3F46B4"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1_13" x1="27.7796" y1="27.2236" x2="-12.4492" y2="-10.1268" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5961DC"/>
                    <stop offset="0.3574" stopColor="#434DE7"/>
                    <stop offset="1" stopColor="#FC2A01"/>
                </linearGradient>
                <linearGradient id="paint4_linear_1_13" x1="4.79657" y1="1.27273" x2="29.4902" y2="1.27273" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    rate_arrow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.00002 1C8.24495 1.00003 8.48136 1.08996 8.66439 1.25272C8.84742 1.41547 8.96436 1.63975 8.99302 1.883L9.00002 2V11.585L12.293 8.293L12.387 8.212C12.5881 8.05974 12.8393 7.98909 13.0902 8.01419C13.3412 8.03929 13.5734 8.15829 13.7403 8.34735C13.9073 8.53642 13.9966 8.78157 13.9904 9.03371C13.9842 9.28585 13.883 9.52634 13.707 9.707L8.71602 14.699C8.68702 14.729 8.65502 14.758 8.62202 14.784L8.58202 14.814L8.52002 14.855L8.49302 14.871L8.41502 14.911L8.38202 14.925L8.31502 14.95L8.26402 14.965L8.20402 14.98L8.15102 14.989L8.08802 14.997L8.03502 15H8.00002L7.96702 14.999L7.91502 14.996L8.00002 15C7.95048 15 7.90101 14.9963 7.85202 14.989L7.79802 14.979L7.74002 14.966L7.68802 14.95L7.61902 14.925L7.58602 14.91L7.51602 14.875L7.47702 14.852L7.41402 14.81C7.37141 14.7788 7.33129 14.7444 7.29402 14.707L7.38402 14.787C7.34902 14.76 7.31602 14.73 7.28502 14.698L2.29302 9.707L2.21202 9.619C2.10629 9.48922 2.03483 9.33497 2.00419 9.17039C1.97356 9.00582 1.98473 8.83619 2.03668 8.67706C2.08863 8.51792 2.1797 8.37437 2.30153 8.25957C2.42337 8.14478 2.57208 8.0624 2.73402 8.02C2.90583 7.975 3.08653 7.97657 3.25754 8.02455C3.42855 8.07253 3.58369 8.16519 3.70702 8.293L7.00002 11.585V2C7.00002 1.73478 7.10537 1.48043 7.29291 1.29289C7.48045 1.10536 7.7348 1 8.00002 1Z" fill="#FF6A55"/>
        </svg>
    ),
    generated_proxy: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6665 10.6667H13.3332C11.8604 10.6667 10.6665 11.8606 10.6665 13.3334V18.6667C10.6665 20.1395 11.8604 21.3334 13.3332 21.3334H18.6665C20.1393 21.3334 21.3332 20.1395 21.3332 18.6667V13.3334C21.3332 11.8606 20.1393 10.6667 18.6665 10.6667Z" fill="url(#paint0_linear_1_25)"/>
            <path d="M18.6665 10.6667H13.3332C11.8604 10.6667 10.6665 11.8606 10.6665 13.3334V18.6667C10.6665 20.1395 11.8604 21.3334 13.3332 21.3334H18.6665C20.1393 21.3334 21.3332 20.1395 21.3332 18.6667V13.3334C21.3332 11.8606 20.1393 10.6667 18.6665 10.6667Z" fill="url(#paint1_linear_1_25)"/>
            <path d="M18.6665 10.6667H13.3332C11.8604 10.6667 10.6665 11.8606 10.6665 13.3334V18.6667C10.6665 20.1395 11.8604 21.3334 13.3332 21.3334H18.6665C20.1393 21.3334 21.3332 20.1395 21.3332 18.6667V13.3334C21.3332 11.8606 20.1393 10.6667 18.6665 10.6667Z" fill="url(#paint2_linear_1_25)"/>
            <g filter="url(#filter0_i_1_25)">
                <path d="M12.0002 6.66667C12.7365 6.66667 13.3335 6.06971 13.3335 5.33333C13.3335 4.59695 12.7365 4 12.0002 4H9.3335C6.38798 4 4.00016 6.38781 4.00016 9.33333V12C4.00016 12.7364 4.59712 13.3333 5.3335 13.3333C6.06988 13.3333 6.66683 12.7364 6.66683 12V9.33333C6.66683 7.86057 7.86074 6.66667 9.3335 6.66667H12.0002Z" fill="#F1F1F1" fill-opacity="0.1"/>
                <path d="M12.0002 6.66667C12.7365 6.66667 13.3335 6.06971 13.3335 5.33333C13.3335 4.59695 12.7365 4 12.0002 4H9.3335C6.38798 4 4.00016 6.38781 4.00016 9.33333V12C4.00016 12.7364 4.59712 13.3333 5.3335 13.3333C6.06988 13.3333 6.66683 12.7364 6.66683 12V9.33333C6.66683 7.86057 7.86074 6.66667 9.3335 6.66667H12.0002Z" fill="url(#paint3_linear_1_25)" fill-opacity="0.2"/>
                <path d="M12.0002 6.66667C12.7365 6.66667 13.3335 6.06971 13.3335 5.33333C13.3335 4.59695 12.7365 4 12.0002 4H9.3335C6.38798 4 4.00016 6.38781 4.00016 9.33333V12C4.00016 12.7364 4.59712 13.3333 5.3335 13.3333C6.06988 13.3333 6.66683 12.7364 6.66683 12V9.33333C6.66683 7.86057 7.86074 6.66667 9.3335 6.66667H12.0002Z" fill="black" fill-opacity="0.5"/>
                <path d="M6.66683 22.6667V18.6667H25.3335V22.6667C25.3335 24.1394 24.1396 25.3333 22.6668 25.3333H20.0002C19.2638 25.3333 18.6668 25.9303 18.6668 26.6667C18.6668 27.403 19.2638 28 20.0002 28H22.6668C25.6123 28 28.0002 25.6122 28.0002 22.6667V18.6667H29.3335C30.0699 18.6667 30.6668 18.0697 30.6668 17.3333C30.6668 16.597 30.0699 16 29.3335 16H2.66683C1.93045 16 1.3335 16.597 1.3335 17.3333C1.3335 18.0697 1.93045 18.6667 2.66683 18.6667H4.00016V22.6667C4.00016 25.6122 6.38798 28 9.3335 28H12.0002C12.7365 28 13.3335 27.403 13.3335 26.6667C13.3335 25.9303 12.7365 25.3333 12.0002 25.3333H9.3335C7.86074 25.3333 6.66683 24.1394 6.66683 22.6667Z" fill="#F1F1F1" fill-opacity="0.1"/>
                <path d="M6.66683 22.6667V18.6667H25.3335V22.6667C25.3335 24.1394 24.1396 25.3333 22.6668 25.3333H20.0002C19.2638 25.3333 18.6668 25.9303 18.6668 26.6667C18.6668 27.403 19.2638 28 20.0002 28H22.6668C25.6123 28 28.0002 25.6122 28.0002 22.6667V18.6667H29.3335C30.0699 18.6667 30.6668 18.0697 30.6668 17.3333C30.6668 16.597 30.0699 16 29.3335 16H2.66683C1.93045 16 1.3335 16.597 1.3335 17.3333C1.3335 18.0697 1.93045 18.6667 2.66683 18.6667H4.00016V22.6667C4.00016 25.6122 6.38798 28 9.3335 28H12.0002C12.7365 28 13.3335 27.403 13.3335 26.6667C13.3335 25.9303 12.7365 25.3333 12.0002 25.3333H9.3335C7.86074 25.3333 6.66683 24.1394 6.66683 22.6667Z" fill="url(#paint4_linear_1_25)" fill-opacity="0.2"/>
                <path d="M6.66683 22.6667V18.6667H25.3335V22.6667C25.3335 24.1394 24.1396 25.3333 22.6668 25.3333H20.0002C19.2638 25.3333 18.6668 25.9303 18.6668 26.6667C18.6668 27.403 19.2638 28 20.0002 28H22.6668C25.6123 28 28.0002 25.6122 28.0002 22.6667V18.6667H29.3335C30.0699 18.6667 30.6668 18.0697 30.6668 17.3333C30.6668 16.597 30.0699 16 29.3335 16H2.66683C1.93045 16 1.3335 16.597 1.3335 17.3333C1.3335 18.0697 1.93045 18.6667 2.66683 18.6667H4.00016V22.6667C4.00016 25.6122 6.38798 28 9.3335 28H12.0002C12.7365 28 13.3335 27.403 13.3335 26.6667C13.3335 25.9303 12.7365 25.3333 12.0002 25.3333H9.3335C7.86074 25.3333 6.66683 24.1394 6.66683 22.6667Z" fill="#6F767E" fill-opacity="0.5"/>
                <path d="M26.6668 13.3333C25.9305 13.3333 25.3335 12.7364 25.3335 12V9.33333C25.3335 7.86057 24.1396 6.66667 22.6668 6.66667H20.0002C19.2638 6.66667 18.6668 6.06971 18.6668 5.33333C18.6668 4.59695 19.2638 4 20.0002 4H22.6668C25.6123 4 28.0002 6.38781 28.0002 9.33333V12C28.0002 12.7364 27.4032 13.3333 26.6668 13.3333Z" fill="#F1F1F1" fill-opacity="0.1"/>
                <path d="M26.6668 13.3333C25.9305 13.3333 25.3335 12.7364 25.3335 12V9.33333C25.3335 7.86057 24.1396 6.66667 22.6668 6.66667H20.0002C19.2638 6.66667 18.6668 6.06971 18.6668 5.33333C18.6668 4.59695 19.2638 4 20.0002 4H22.6668C25.6123 4 28.0002 6.38781 28.0002 9.33333V12C28.0002 12.7364 27.4032 13.3333 26.6668 13.3333Z" fill="url(#paint5_linear_1_25)" fill-opacity="0.2"/>
                <path d="M26.6668 13.3333C25.9305 13.3333 25.3335 12.7364 25.3335 12V9.33333C25.3335 7.86057 24.1396 6.66667 22.6668 6.66667H20.0002C19.2638 6.66667 18.6668 6.06971 18.6668 5.33333C18.6668 4.59695 19.2638 4 20.0002 4H22.6668C25.6123 4 28.0002 6.38781 28.0002 9.33333V12C28.0002 12.7364 27.4032 13.3333 26.6668 13.3333Z" fill="black" fill-opacity="0.5"/>
                <path d="M6.66683 18.5333H6.5335V18.6667V22.6667C6.5335 24.2131 7.7871 25.4667 9.3335 25.4667H12.0002C12.6629 25.4667 13.2002 26.0039 13.2002 26.6667C13.2002 27.3294 12.6629 27.8667 12.0002 27.8667H9.3335C6.46162 27.8667 4.1335 25.5385 4.1335 22.6667V18.6667V18.5333H4.00016H2.66683C2.00409 18.5333 1.46683 17.9961 1.46683 17.3333C1.46683 16.6706 2.00409 16.1333 2.66683 16.1333H29.3335C29.9962 16.1333 30.5335 16.6706 30.5335 17.3333C30.5335 17.9961 29.9962 18.5333 29.3335 18.5333H28.0002H27.8668V18.6667V22.6667C27.8668 25.5385 25.5387 27.8667 22.6668 27.8667H20.0002C19.3374 27.8667 18.8002 27.3294 18.8002 26.6667C18.8002 26.0039 19.3374 25.4667 20.0002 25.4667H22.6668C24.2132 25.4667 25.4668 24.2131 25.4668 22.6667V18.6667V18.5333H25.3335H6.66683ZM13.2002 5.33333C13.2002 5.99608 12.6629 6.53333 12.0002 6.53333H9.3335C7.7871 6.53333 6.5335 7.78694 6.5335 9.33333V12C6.5335 12.6627 5.99624 13.2 5.3335 13.2C4.67075 13.2 4.1335 12.6627 4.1335 12V9.33333C4.1335 6.46145 6.46162 4.13333 9.3335 4.13333H12.0002C12.6629 4.13333 13.2002 4.67059 13.2002 5.33333ZM26.6668 13.2C26.0041 13.2 25.4668 12.6627 25.4668 12V9.33333C25.4668 7.78694 24.2132 6.53333 22.6668 6.53333H20.0002C19.3374 6.53333 18.8002 5.99608 18.8002 5.33333C18.8002 4.67059 19.3374 4.13333 20.0002 4.13333H22.6668C25.5387 4.13333 27.8668 6.46145 27.8668 9.33333V12C27.8668 12.6627 27.3296 13.2 26.6668 13.2Z" stroke="url(#paint6_linear_1_25)" stroke-opacity="0.1" stroke-width="0.266667"/>
            </g>
            <defs>
                <filter id="filter0_i_1_25" x="1.3335" y="4" width="29.3333" height="24.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_25"/>
                </filter>
                <linearGradient id="paint0_linear_1_25" x1="11.1109" y1="12.8" x2="22.7385" y2="14.4149" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1_25" x1="10.6665" y1="16.0036" x2="21.3332" y2="16.0036" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1_25" x1="20.679" y1="21.0376" x2="9.40636" y2="8.64898" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5961DC"/>
                    <stop offset="0.3574" stop-color="#434DE7"/>
                    <stop offset="1" stop-color="#3F46B4"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1_25" x1="28.8677" y1="27.3345" x2="-4.84068" y2="-14.9433" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.3574"/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint4_linear_1_25" x1="28.8677" y1="27.3345" x2="-4.84068" y2="-14.9433" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.3574"/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint5_linear_1_25" x1="28.8677" y1="27.3345" x2="-4.84067" y2="-14.9433" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.3574"/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint6_linear_1_25" x1="2.25016" y1="5.09091" x2="30.6668" y2="5.09091" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    yellow_arrow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g filter="url(#filter0_bi_41_2094)">
                <path d="M10.8499 9.50559C10.9166 9.54415 11 9.49604 11 9.41902V8.1774C11 7.26816 12.0048 6.70309 12.8018 7.1641L19.4106 10.9867C20.1965 11.4413 20.1965 12.5587 19.4106 13.0133L12.8018 16.8359C12.0048 17.2969 11 16.7318 11 15.8226V14.581C11 14.504 10.9166 14.4559 10.8499 14.4944L6.80177 16.8359C6.00476 17.2969 5 16.7318 5 15.8226V8.1774C5 7.26816 6.00476 6.70309 6.80177 7.1641L10.8499 9.50559Z" fill="#F1F1F1" fill-opacity="0.58"/>
                <path d="M10.7999 9.59215C10.9332 9.66927 11.1 9.57306 11.1 9.41902V8.1774C11.1 7.34807 12.0188 6.82676 12.7517 7.25066L19.3605 11.0733C20.0798 11.4893 20.0798 12.5107 19.3605 12.9267L12.7517 16.7493C12.0188 17.1732 11.1 16.6519 11.1 15.8226V14.581C11.1 14.4269 10.9332 14.3307 10.7999 14.4079L6.7517 16.7493C6.01883 17.1732 5.1 16.6519 5.1 15.8226V8.1774C5.1 7.34807 6.01883 6.82676 6.7517 7.25066L10.7999 9.59215Z" stroke="url(#paint0_linear_41_2094)" stroke-opacity="0.1" stroke-width="0.2"/>
            </g>
            <defs>
                <filter id="filter0_bi_41_2094" x="2" y="4" width="21" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_41_2094"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_41_2094" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_41_2094"/>
                </filter>
                <linearGradient id="paint0_linear_41_2094" x1="19.5312" y1="16.5455" x2="5" y2="16.5455" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    dark_arrow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none">
            <g filter="url(#filter0_bi_6_2129)">
                <path d="M5.84993 2.50559C5.9166 2.54415 6 2.49604 6 2.41902V1.1774C6 0.268159 7.00475 -0.296906 7.80177 0.164097L14.4106 3.9867C15.1965 4.44125 15.1965 5.55875 14.4106 6.0133L7.80177 9.8359C7.00475 10.2969 6 9.73184 6 8.8226V7.58098C6 7.50396 5.9166 7.45585 5.84993 7.49441L1.80177 9.8359C1.00476 10.2969 0 9.73184 0 8.8226V1.1774C0 0.268159 1.00476 -0.296906 1.80177 0.164097L5.84993 2.50559Z" fill="#03030C" fill-opacity="0.4"/>
                <path d="M5.79986 2.59215C5.93319 2.66927 6.1 2.57306 6.1 2.41902V1.1774C6.1 0.348072 7.01883 -0.173243 7.7517 0.25066L14.3605 4.07326C15.0798 4.48931 15.0798 5.51069 14.3605 5.92674L7.7517 9.74934C7.01883 10.1732 6.1 9.65193 6.1 8.8226V7.58098C6.1 7.42695 5.9332 7.33073 5.79986 7.40785L1.7517 9.74934C1.01883 10.1732 0.1 9.65193 0.1 8.8226V1.1774C0.1 0.348072 1.01883 -0.173243 1.7517 0.25066L5.79986 2.59215Z" stroke="url(#paint0_linear_6_2129)" stroke-opacity="0.1" stroke-width="0.2"/>
            </g>
            <defs>
                <filter id="filter0_bi_6_2129" x="-3" y="-3" width="21" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6_2129"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6_2129" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6_2129"/>
                </filter>
                <linearGradient id="paint0_linear_6_2129" x1="14.5312" y1="9.54545" x2="-4.84622e-07" y2="9.54545" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    special_arrow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g filter="url(#filter0_bi_39_479)">
                <path d="M10.8499 9.50559C10.9166 9.54415 11 9.49604 11 9.41902V8.1774C11 7.26816 12.0048 6.70309 12.8018 7.1641L19.4106 10.9867C20.1965 11.4413 20.1965 12.5587 19.4106 13.0133L12.8018 16.8359C12.0048 17.2969 11 16.7318 11 15.8226V14.581C11 14.504 10.9166 14.4559 10.8499 14.4944L6.80177 16.8359C6.00476 17.2969 5 16.7318 5 15.8226V8.1774C5 7.26816 6.00476 6.70309 6.80177 7.1641L10.8499 9.50559Z" fill="var(--re-special-arrow-bg)" fill-opacity="0.58"/>
                <path d="M10.7999 9.59215C10.9332 9.66927 11.1 9.57306 11.1 9.41902V8.1774C11.1 7.34807 12.0188 6.82676 12.7517 7.25066L19.3605 11.0733C20.0798 11.4893 20.0798 12.5107 19.3605 12.9267L12.7517 16.7493C12.0188 17.1732 11.1 16.6519 11.1 15.8226V14.581C11.1 14.4269 10.9332 14.3307 10.7999 14.4079L6.7517 16.7493C6.01883 17.1732 5.1 16.6519 5.1 15.8226V8.1774C5.1 7.34807 6.01883 6.82676 6.7517 7.25066L10.7999 9.59215Z" stroke="url(#paint0_linear_39_479)" stroke-opacity="0.1" stroke-width="0.2"/>
            </g>
            <defs>
                <filter id="filter0_bi_39_479" x="2" y="4" width="21" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_39_479"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_39_479" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_39_479"/>
                </filter>
                <linearGradient id="paint0_linear_39_479" x1="19.5312" y1="16.5455" x2="5" y2="16.5455" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    light_arrow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g filter="url(#filter0_bi_6_2271)">
                <path d="M10.8499 9.50559C10.9166 9.54415 11 9.49604 11 9.41902V8.1774C11 7.26816 12.0048 6.70309 12.8018 7.1641L19.4106 10.9867C20.1965 11.4413 20.1965 12.5587 19.4106 13.0133L12.8018 16.8359C12.0048 17.2969 11 16.7318 11 15.8226V14.581C11 14.504 10.9166 14.4559 10.8499 14.4944L6.80177 16.8359C6.00476 17.2969 5 16.7318 5 15.8226V8.1774C5 7.26816 6.00476 6.70309 6.80177 7.1641L10.8499 9.50559Z" fill="#F1F1F1" fill-opacity="0.58"/>
                <path d="M10.7999 9.59215C10.9332 9.66927 11.1 9.57306 11.1 9.41902V8.1774C11.1 7.34807 12.0188 6.82676 12.7517 7.25066L19.3605 11.0733C20.0798 11.4893 20.0798 12.5107 19.3605 12.9267L12.7517 16.7493C12.0188 17.1732 11.1 16.6519 11.1 15.8226V14.581C11.1 14.4269 10.9332 14.3307 10.7999 14.4079L6.7517 16.7493C6.01883 17.1732 5.1 16.6519 5.1 15.8226V8.1774C5.1 7.34807 6.01883 6.82676 6.7517 7.25066L10.7999 9.59215Z" stroke="url(#paint0_linear_6_2271)" stroke-opacity="0.1" stroke-width="0.2"/>
            </g>
            <defs>
                <filter id="filter0_bi_6_2271" x="2" y="4" width="21" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6_2271"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6_2271" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6_2271"/>
                </filter>
                <linearGradient id="paint0_linear_6_2271" x1="19.5312" y1="16.5455" x2="5" y2="16.5455" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    selected: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_16_4072)">
                <path d="M18.3333 9.2333V9.99997C18.3323 11.797 17.7504 13.5455 16.6744 14.9848C15.5984 16.4241 14.086 17.477 12.3628 17.9866C10.6395 18.4961 8.79768 18.4349 7.11202 17.8121C5.42636 17.1894 3.98717 16.0384 3.00909 14.5309C2.03101 13.0233 1.56645 11.24 1.68469 9.4469C1.80293 7.65377 2.49763 5.94691 3.66519 4.58086C4.83275 3.21482 6.41061 2.26279 8.16345 1.86676C9.91629 1.47073 11.7502 1.65192 13.3916 2.3833M18.3333 3.3333L9.99996 11.675L7.49996 9.17497" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_16_4072">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    ),
    list: (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3333 5.66666C12.1157 5.66666 12.75 6.30092 12.75 7.08332V26.9167C12.75 27.6991 12.1157 28.3333 11.3333 28.3333H7.08333C5.51853 28.3333 4.25 27.0648 4.25 25.5V8.49999C4.25 6.93518 5.51853 5.66666 7.08333 5.66666H11.3333Z" fill="url(#paint0_linear_1_40)"/>
            <path d="M11.3333 5.66666C12.1157 5.66666 12.75 6.30092 12.75 7.08332V26.9167C12.75 27.6991 12.1157 28.3333 11.3333 28.3333H7.08333C5.51853 28.3333 4.25 27.0648 4.25 25.5V8.49999C4.25 6.93518 5.51853 5.66666 7.08333 5.66666H11.3333Z" fill="url(#paint1_linear_1_40)"/>
            <path d="M11.3333 5.66666C12.1157 5.66666 12.75 6.30092 12.75 7.08332V26.9167C12.75 27.6991 12.1157 28.3333 11.3333 28.3333H7.08333C5.51853 28.3333 4.25 27.0648 4.25 25.5V8.49999C4.25 6.93518 5.51853 5.66666 7.08333 5.66666H11.3333Z" fill="url(#paint2_linear_1_40)"/>
            <g filter="url(#filter0_i_1_40)">
                <path d="M11.3335 7.08333C11.3335 5.74769 11.3335 5.07986 11.7484 4.66493C12.1634 4.25 12.8312 4.25 14.1668 4.25H24.0835C26.7548 4.25 28.0904 4.25 28.9203 5.07986C29.7502 5.90973 29.7502 7.24537 29.7502 9.91667V24.0833C29.7502 26.7546 29.7502 28.0903 28.9203 28.9201C28.0904 29.75 26.7548 29.75 24.0835 29.75H14.1668C12.8312 29.75 12.1634 29.75 11.7484 29.3351C11.3335 28.9201 11.3335 28.2523 11.3335 26.9167V7.08333Z" fill="#F1F1F1" fill-opacity="0.1"/>
                <path d="M11.3335 7.08333C11.3335 5.74769 11.3335 5.07986 11.7484 4.66493C12.1634 4.25 12.8312 4.25 14.1668 4.25H24.0835C26.7548 4.25 28.0904 4.25 28.9203 5.07986C29.7502 5.90973 29.7502 7.24537 29.7502 9.91667V24.0833C29.7502 26.7546 29.7502 28.0903 28.9203 28.9201C28.0904 29.75 26.7548 29.75 24.0835 29.75H14.1668C12.8312 29.75 12.1634 29.75 11.7484 29.3351C11.3335 28.9201 11.3335 28.2523 11.3335 26.9167V7.08333Z" fill="url(#paint3_linear_1_40)" fill-opacity="0.2"/>
                <path d="M11.3335 7.08333C11.3335 5.74769 11.3335 5.07986 11.7484 4.66493C12.1634 4.25 12.8312 4.25 14.1668 4.25H24.0835C26.7548 4.25 28.0904 4.25 28.9203 5.07986C29.7502 5.90973 29.7502 7.24537 29.7502 9.91667V24.0833C29.7502 26.7546 29.7502 28.0903 28.9203 28.9201C28.0904 29.75 26.7548 29.75 24.0835 29.75H14.1668C12.8312 29.75 12.1634 29.75 11.7484 29.3351C11.3335 28.9201 11.3335 28.2523 11.3335 26.9167V7.08333Z" fill="black" fill-opacity="0.5"/>
                <path d="M11.4752 7.08333C11.4752 6.41151 11.4755 5.92189 11.5258 5.54774C11.5755 5.17767 11.6718 4.94186 11.8486 4.76511C12.0254 4.58835 12.2612 4.49202 12.6312 4.44227C13.0054 4.39197 13.495 4.39167 14.1668 4.39167H24.0835C25.4231 4.39167 26.4136 4.39197 27.1736 4.49414C27.9294 4.59576 28.4359 4.79581 28.8201 5.18004C29.2043 5.56426 29.4044 6.07076 29.506 6.82661C29.6082 7.58653 29.6085 8.57702 29.6085 9.91667V24.0833C29.6085 25.423 29.6082 26.4135 29.506 27.1734C29.4044 27.9292 29.2043 28.4357 28.8201 28.82C28.4359 29.2042 27.9294 29.4042 27.1736 29.5059C26.4136 29.608 25.4231 29.6083 24.0835 29.6083H14.1668C13.495 29.6083 13.0054 29.608 12.6312 29.5577C12.2612 29.508 12.0254 29.4117 11.8486 29.2349C11.6718 29.0581 11.5755 28.8223 11.5258 28.4523C11.4755 28.0781 11.4752 27.5885 11.4752 26.9167V7.08333Z" stroke="url(#paint4_linear_1_40)" stroke-opacity="0.1" stroke-width="0.283333"/>
            </g>
            <g filter="url(#filter1_i_1_40)">
                <path d="M21.2498 9.91666H15.5832C14.8008 9.91666 14.1665 10.5509 14.1665 11.3333C14.1665 12.1157 14.8008 12.75 15.5832 12.75H21.2498C22.0322 12.75 22.6665 12.1157 22.6665 11.3333C22.6665 10.5509 22.0322 9.91666 21.2498 9.91666Z" fill="white" fill-opacity="0.16"/>
            </g>
            <g filter="url(#filter2_i_1_40)">
                <path d="M25.4998 15.5833H15.5832C14.8008 15.5833 14.1665 16.2176 14.1665 17C14.1665 17.7824 14.8008 18.4166 15.5832 18.4166H25.4998C26.2822 18.4166 26.9165 17.7824 26.9165 17C26.9165 16.2176 26.2822 15.5833 25.4998 15.5833Z" fill="white" fill-opacity="0.16"/>
            </g>
            <g filter="url(#filter3_i_1_40)">
                <path d="M25.4998 21.25H15.5832C14.8008 21.25 14.1665 21.8843 14.1665 22.6667C14.1665 23.4491 14.8008 24.0833 15.5832 24.0833H25.4998C26.2822 24.0833 26.9165 23.4491 26.9165 22.6667C26.9165 21.8843 26.2822 21.25 25.4998 21.25Z" fill="white" fill-opacity="0.16"/>
            </g>
            <defs>
                <filter id="filter0_i_1_40" x="11.3335" y="4.25" width="18.4167" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_40"/>
                </filter>
                <filter id="filter1_i_1_40" x="14.1665" y="9.91666" width="8.5" height="3.33333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="0.25"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_40"/>
                </filter>
                <filter id="filter2_i_1_40" x="14.1665" y="15.5833" width="12.75" height="3.33333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="0.25"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_40"/>
                </filter>
                <filter id="filter3_i_1_40" x="14.1665" y="21.25" width="12.75" height="3.33333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="0.25"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_40"/>
                </filter>
                <linearGradient id="paint0_linear_1_40" x1="11.05" y1="6.6111" x2="2.84734" y2="28.7583" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1_40" x1="8.49717" y1="5.66666" x2="8.49717" y2="28.3333" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1_40" x1="4.4857" y1="26.9431" x2="20.6495" y2="21.4277" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5961DC"/>
                    <stop offset="0.3574" stop-color="#434DE7"/>
                    <stop offset="1" stop-color="#3F46B4"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1_40" x1="28.6206" y1="29.0429" x2="-6.52821" y2="2.99306" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.3574"/>
                    <stop offset="1"/>
                </linearGradient>
                <linearGradient id="paint4_linear_1_40" x1="11.909" y1="5.40909" x2="29.7502" y2="5.40909" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1"/>
                </linearGradient>
            </defs>
        </svg>
    
    ),
    menu_resi: (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <g filter="url(#filter0_bi_39_960)">
                <path d="M16.9174 24.5143C15.7372 25.1619 14.2628 25.1619 13.0826 24.5143L4.35311 19.7242C3.54897 19.2829 3.54897 18.2171 4.35311 17.7758L13.0826 12.9857C14.2628 12.3381 15.7372 12.3381 16.9174 12.9857L25.6469 17.7758C26.451 18.2171 26.451 19.2829 25.6469 19.7242L16.9174 24.5143Z" fill="white" fill-opacity="0.17"/>
                <path d="M16.9174 24.5143C15.7372 25.1619 14.2628 25.1619 13.0826 24.5143L4.35311 19.7242C3.54897 19.2829 3.54897 18.2171 4.35311 17.7758L13.0826 12.9857C14.2628 12.3381 15.7372 12.3381 16.9174 12.9857L25.6469 17.7758C26.451 18.2171 26.451 19.2829 25.6469 19.7242L16.9174 24.5143Z" fill="var(--re-svg-icon-bg)" fill-opacity="0.5"/>
                <path d="M16.8572 24.4047C15.7145 25.0318 14.2855 25.0318 13.1428 24.4047L4.41324 19.6146C3.69559 19.2208 3.69559 18.2792 4.41324 17.8854L13.1428 13.0953C14.2855 12.4682 15.7145 12.4682 16.8572 13.0953L25.5868 17.8854C26.3044 18.2792 26.3044 19.2208 25.5868 19.6146L16.8572 24.4047Z" stroke="url(#paint0_linear_39_960)" stroke-opacity="0.1" stroke-width="0.25"/>
            </g>
            <path d="M16.9174 20.7643C15.7372 21.4119 14.2628 21.4119 13.0826 20.7643L4.35311 15.9742C3.54897 15.5329 3.54897 14.4671 4.35311 14.0258L13.0826 9.23569C14.2628 8.5881 15.7372 8.5881 16.9174 9.23569L25.6469 14.0258C26.451 14.4671 26.451 15.5329 25.6469 15.9742L16.9174 20.7643Z" fill="url(#paint1_linear_39_960)"/>
            <path d="M16.9174 20.7643C15.7372 21.4119 14.2628 21.4119 13.0826 20.7643L4.35311 15.9742C3.54897 15.5329 3.54897 14.4671 4.35311 14.0258L13.0826 9.23569C14.2628 8.5881 15.7372 8.5881 16.9174 9.23569L25.6469 14.0258C26.451 14.4671 26.451 15.5329 25.6469 15.9742L16.9174 20.7643Z" fill="url(#paint2_linear_39_960)"/>
            <path d="M16.9174 20.7643C15.7372 21.4119 14.2628 21.4119 13.0826 20.7643L4.35311 15.9742C3.54897 15.5329 3.54897 14.4671 4.35311 14.0258L13.0826 9.23569C14.2628 8.5881 15.7372 8.5881 16.9174 9.23569L25.6469 14.0258C26.451 14.4671 26.451 15.5329 25.6469 15.9742L16.9174 20.7643Z" fill="url(#paint3_linear_39_960)"/>
            <path d="M16.9174 20.7643C15.7372 21.4119 14.2628 21.4119 13.0826 20.7643L4.35311 15.9742C3.54897 15.5329 3.54897 14.4671 4.35311 14.0258L13.0826 9.23569C14.2628 8.5881 15.7372 8.5881 16.9174 9.23569L25.6469 14.0258C26.451 14.4671 26.451 15.5329 25.6469 15.9742L16.9174 20.7643Z" fill="url(#paint4_linear_39_960)"/>
            <g filter="url(#filter1_bi_39_960)">
                <path d="M16.9174 17.0143C15.7372 17.6619 14.2628 17.6619 13.0826 17.0143L4.35311 12.2242C3.54897 11.7829 3.54897 10.7171 4.35311 10.2758L13.0826 5.48569C14.2628 4.8381 15.7372 4.8381 16.9174 5.48569L25.6469 10.2758C26.451 10.7171 26.451 11.7829 25.6469 12.2242L16.9174 17.0143Z" fill="white" fill-opacity="0.17"/>
                <path d="M16.9174 17.0143C15.7372 17.6619 14.2628 17.6619 13.0826 17.0143L4.35311 12.2242C3.54897 11.7829 3.54897 10.7171 4.35311 10.2758L13.0826 5.48569C14.2628 4.8381 15.7372 4.8381 16.9174 5.48569L25.6469 10.2758C26.451 10.7171 26.451 11.7829 25.6469 12.2242L16.9174 17.0143Z" fill="#6F767E" fill-opacity="0.5"/>
                <path d="M16.8572 16.9047C15.7145 17.5318 14.2855 17.5318 13.1428 16.9047L4.41324 12.1146C3.69559 11.7208 3.69559 10.7792 4.41324 10.3854L13.1428 5.59528C14.2855 4.96824 15.7145 4.96824 16.8572 5.59528L25.5868 10.3854C26.3044 10.7792 26.3044 11.7208 25.5868 12.1146L16.8572 16.9047Z" stroke="url(#paint5_linear_39_960)" stroke-opacity="0.1" stroke-width="0.25"/>
            </g>
            <defs>
                <filter id="filter0_bi_39_960" x="0" y="8.75" width="30" height="20" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.875"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_39_960"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_39_960" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.625"/>
                    <feGaussianBlur stdDeviation="1.25"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_39_960"/>
                </filter>
                <filter id="filter1_bi_39_960" x="0" y="1.25" width="30" height="20" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.875"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_39_960"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_39_960" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.625"/>
                    <feGaussianBlur stdDeviation="1.25"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_39_960"/>
                </filter>
                <linearGradient id="paint0_linear_39_960" x1="25.5469" y1="24.4318" x2="3.75" y2="24.4318" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
                <linearGradient id="paint1_linear_39_960" x1="25.3125" y1="18.75" x2="1.78309" y2="12.8676" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FE82DB"/>
                    <stop offset="1" stopColor="#68E4FF"/>
                </linearGradient>
                <linearGradient id="paint2_linear_39_960" x1="26.25" y1="14.9958" x2="3.75" y2="14.9958" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#474EEB"/>
                    <stop offset="1" stopColor="#7177EC"/>
                </linearGradient>
                <linearGradient id="paint3_linear_39_960" x1="26.25" y1="14.9958" x2="3.75" y2="14.9958" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2A53BB"/>
                    <stop offset="1" stopColor="#505EDC"/>
                </linearGradient>
                <linearGradient id="paint4_linear_39_960" x1="5.12999" y1="9.09662" x2="28.6574" y2="55.222" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFA329"/>
                    <stop offset="0.3574" stopColor="#FE7B1C"/>
                    <stop offset="1" stopColor="#FC2A01"/>
                </linearGradient>
                <linearGradient id="paint5_linear_39_960" x1="25.5469" y1="16.9318" x2="3.75" y2="16.9318" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    menu_static: (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <g opacity="0.53">
                <circle cx="15" cy="15" r="7.5" fill="url(#paint0_linear_39_966)"/>
                <circle cx="15" cy="15" r="7.5" fill="url(#paint1_linear_39_966)"/>
                <circle cx="15" cy="15" r="7.5" fill="url(#paint2_linear_39_966)"/>
            </g>
            <g filter="url(#filter0_bi_39_966)">
                <path d="M5.68341 16.9299L17.542 2.69966C17.8704 2.30554 18.5085 2.6026 18.4183 3.10764L16.7857 12.25H23.9325C24.3564 12.25 24.588 12.7444 24.3166 13.0701L12.646 27.0747C12.306 27.4828 11.6485 27.1493 11.7767 26.6339L13.9881 17.75H6.06752C5.6436 17.75 5.41202 17.2556 5.68341 16.9299Z" fill="#F1F1F1" fill-opacity="0.49"/>
                <path d="M5.68341 16.9299L17.542 2.69966C17.8704 2.30554 18.5085 2.6026 18.4183 3.10764L16.7857 12.25H23.9325C24.3564 12.25 24.588 12.7444 24.3166 13.0701L12.646 27.0747C12.306 27.4828 11.6485 27.1493 11.7767 26.6339L13.9881 17.75H6.06752C5.6436 17.75 5.41202 17.2556 5.68341 16.9299Z" fill="var(--re-svg-icon-bg)" fill-opacity="0.5"/>
                <path d="M16.6627 12.228L16.6364 12.375H16.7857H23.9325C24.2504 12.375 24.4241 12.7458 24.2206 12.9901L12.55 26.9947C12.295 27.3007 11.8018 27.0506 11.898 26.6641L14.1094 17.7802L14.148 17.625H13.9881H6.06752C5.74958 17.625 5.5759 17.2542 5.77944 17.0099L17.638 2.77968C17.8843 2.48409 18.3629 2.70689 18.2952 3.08567L16.6627 12.228Z" stroke="url(#paint3_linear_39_966)" stroke-opacity="0.1" stroke-width="0.25"/>
            </g>
            <defs>
                <filter id="filter0_bi_39_966" x="2.56641" y="-0.482178" width="24.8672" height="30.7393" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_39_966"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_39_966" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_39_966"/>
                </filter>
                <linearGradient id="paint0_linear_39_966" x1="8.125" y1="10.5" x2="24.4762" y2="12.771" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FE82DB"/>
                    <stop offset="1" stopColor="#68E4FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_39_966" x1="7.5" y1="15.005" x2="22.5" y2="15.005" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#18C6EB"/>
                    <stop offset="1" stopColor="#0A91B3"/>
                </linearGradient>
                <linearGradient id="paint2_linear_39_966" x1="21.58" y1="22.0841" x2="5.72792" y2="4.66263" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFA329"/>
                    <stop offset="0.3574" stopColor="#FE7B1C"/>
                    <stop offset="1" stopColor="#FC2A01"/>
                </linearGradient>
                <linearGradient id="paint3_linear_39_966" x1="5.625" y1="2.5" x2="25" y2="2.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    menu_member: (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M7.5 9.80891C7.5 9.19786 7.94177 8.67637 8.5445 8.57592L21.0445 6.49258C21.8064 6.3656 22.5 6.95315 22.5 7.72558V11.25C22.5 11.9404 21.9404 12.5 21.25 12.5H8.75C8.05964 12.5 7.5 11.9404 7.5 11.25V9.80891Z" fill="#1A1D1F"/>
            <g filter="url(#filter0_bi_39_996)">
                <rect x="5" y="10" width="20" height="15" rx="2.5" fill="#F1F1F1" fill-opacity="0.1"/>
                <rect x="5" y="10" width="20" height="15" rx="2.5" fill="url(#paint0_linear_39_996)"/>
                <rect x="5.125" y="10.125" width="19.75" height="14.75" rx="2.375" stroke="url(#paint1_linear_39_996)" stroke-opacity="0.1" stroke-width="0.25"/>
            </g>
            <g filter="url(#filter1_i_39_996)">
                <circle cx="21.875" cy="13.125" r="1.875" fill="white" fill-opacity="0.16"/>
            </g>
            <defs>
                <filter id="filter0_bi_39_996" x="2" y="7" width="26" height="21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_39_996"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_39_996" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_39_996"/>
                </filter>
                <filter id="filter1_i_39_996" x="20" y="11.25" width="3.75" height="4.25" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.5"/>
                    <feGaussianBlur stdDeviation="0.25"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_39_996"/>
                </filter>
                <linearGradient id="paint0_linear_39_996" x1="23.7733" y1="24.5841" x2="8.94609" y2="2.85721" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFA329"/>
                    <stop offset="0.3574" stopColor="#FE7B1C"/>
                    <stop offset="1" stopColor="#FC2A01"/>
                </linearGradient>
                <linearGradient id="paint1_linear_39_996" x1="5.625" y1="10.6818" x2="25" y2="10.6818" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD84DC"/>
                    <stop offset="1" stopColor="#7FD6FA"/>
                </linearGradient>
            </defs>
        </svg>
    )
}