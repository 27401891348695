import { createStore } from 'redux';

const GENERATING_PROXY_LIST = "GENERATING_PROXY_LIST";
const GENERATING_PROXY_ORIGIN_LIST = "GENERATING_PROXY_ORIGIN_LIST";
const SELECT_PLAN = "SELECT_PLAN";
// Define an initial state
const initialState = {
  proxyLists: [],
  proxyOriginList: '',
  selectedPlan: ''
};

// Define a reducer function
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATING_PROXY_LIST:
      return {
        ...state,
        proxyLists: action.payload,
      };

    case GENERATING_PROXY_ORIGIN_LIST:
      return {
        ...state,
        proxyOriginList: action.payload,
      };

    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    default:
      return state;
  }
};

// Create the Redux store
const store = createStore(rootReducer);

export default store;
