import React from "react";
import PropTypes from "prop-types";
import styles from "./Navigation.module.scss";
import logo from "assets/images/logo.svg";
import SideToggle from "./SideToggle/SideToggle";

const Navigation = (props) => {
  const { sideDrawer, clickHandler } = props;

  return (
    <div className={styles.navigation}>
      <span>{props.label}</span>

      <img src={logo} alt="logo" />
      <div className={styles.logoText}>Embargo</div>

      <div className={styles.toggle}>
        <SideToggle show={sideDrawer} clicked={clickHandler} darkTheme />
      </div>
    </div>
  );
};

export default Navigation;
Navigation.propTypes = {
  show: PropTypes.bool,
  sideDrawer: PropTypes.bool,
  scrollStyling: PropTypes.bool,
  clickHandler: PropTypes.func,
  toggleRegisterHandler: PropTypes.func,
};
