import React, { useState, useEffect } from "react";
import Styles from "./isp.module.scss";
import { icon_items } from "../../../pages/ResiDashboard/icon-item";
import Logo from "../../../assets/images/logo.svg";
import checkCircle from "../../../assets/images/signIn/check-circle.png";
import AnimationButton from "../../UI/AnimationButton";
import AnimationButtonGo from "components/UI/AnimationButtonGo";

const ISP = (props) => {
    const { membershipType, cookies, user } = props;
    const [loadingISP, setLoadingISP] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedAmount, setSelectedAmount] = useState("");
    const [calculatedPrice, setCalculatedPrice] = useState("");
    const [fromPrice, setFromPrice] = useState("");
    const [pricePerProxy, setPricePerProxy] = useState("");
    const [proxyType, setProxyType] = useState("isp");
    const [ispStatus, setISPstatus] = useState('');
    const [dcStatus, setDcStatus] = useState('');
    
    const selectedDate = "30"
    
    const selectedPlan = "Embargo ISPs"
    
    const selectedFeature = [
        "30MS Latency",
        "Support Tickets Website",
        "Auto Renewal",
    ];
    
    const RegionList = {
        "DE": "Germany",
        "UK": "United Kingdom",
        "US": "Ashburn"
    }
    
    const AmountList = [
        "1",
        "3",
        "5",
        "10",
        "25",
        "50",
        "100",
        "255",
    ]
    
    const price_aio = {
        '1': { '30': 3.0, '90': 9.0, '1': 5.0 },
        '3': { '30': 6.6, '90': 18.0, '1': 7.0 },
        '5': { '30': 11.0, '90': 37, '1': 5.0 },
        '10': { '30': 22.0, '90': 46.0, '1': 5.0 },
        '25': { '30': 50.0, '90': 114.0, '1': 12.0 },
        '50': { '30': 97.5, '90': 228.0, '1': 25.0 },
        '100': { '30': 185.0, '90': 456.0, '1': 50.0 },
        '255': { '30': 420.75, '90': 1110.0, '1': 125.0 }
    };

    const price_santo = {
        free: {
            '1': { '30': 3.0, '90': 9.0, '1': 5.0 },
            '5': { '30': 12.0, '90': 37, '1': 5.0 },
            '10': { '30': 17.99, '90': 46.0, '1': 5.0 },
            '25': { '30': 38.99, '90': 114.0, '1': 12.0 },
            '50': { '30': 72.99, '90': 228.0, '1': 25.0 },
            '100': { '30': 139.99, '90': 456.0, '1': 50.0 },
            '255': { '30': 330.0, '90': 1110.0, '1': 125.0 }
        },
        paid: {
            '1': { '30': 3.0, '90': 9.0, '1': 5.0 },
            '5': { '30': 12.0, '90': 37, '1': 5.0 },
            '10': { '30': 17.99, '90': 46.0, '1': 5.0 },
            '25': { '30': 38.99, '90': 114.0, '1': 12.0 },
            '50': { '30': 72.99, '90': 228.0, '1': 25.0 },
            '100': { '30': 139.99, '90': 456.0, '1': 50.0 },
            '255': { '30': 330.0, '90': 1110.0, '1': 125.0 }
        }
    };

    const price_aio_from = {
        free: 1.45,
        paid: 1.45
    }

    const price_surrento_from = {
        free: 1.29,
        paid: 1.29
    }

    const setProxyStatus = (proxyType, stockData) => {
        const setStatus = (status) => {
            switch (proxyType) {
                case 'isp':
                    setISPstatus(status);
                    break;
                case 'dc':
                    setDcStatus(status);
                    break;
                default:
                // Handle unknown proxy types
            }
        };

        setStatus(stockData[proxyType] === 'instock');
    };

    const checkAllProxyStocks = () => {
        const accessToken = cookies.get('access_token');
        fetch('https://proxyapi.embargotickets.com/api/v1/checkStock', {
            method: "POST",
            body: JSON.stringify({
                "discord_token": accessToken,
            })
        })
            .then(response => response.json())
            .then(data => {
                const proxyTypes = ['isp', 'dc'];
                proxyTypes.forEach(proxyType => {
                    setProxyStatus(proxyType, data);
                });

                props.setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                alert('Error checking stock, please try again.');
            });
    };

    useEffect(() => {
        setFromPrice(1.65)
        checkAllProxyStocks();
    }, [])

    const handleSelectRegion = (event) => {
        const selectedValue = event.target.value;
        setSelectedRegion(selectedValue);
        calculatePrice(selectedPlan, selectedAmount, selectedDate, membershipType);
        calculatePerProxy(selectedPlan, selectedAmount, selectedDate, membershipType);
    }

    const handleSelectedAmount = (event) => {
        const selectedAmount = event.target.value
        setSelectedAmount(selectedAmount);
        calculatePrice(selectedPlan, selectedAmount, selectedDate);
        calculatePerProxy(selectedPlan, selectedAmount, selectedDate);
    }


    const calculatePrice = (plan, amount, date) => {

        if (plan !== "" && amount !== "") {
            let calculatedPrice = "";
            calculatedPrice = price_aio[amount][date];
            setCalculatedPrice(calculatedPrice);
        }

    }

    const calculatePerProxy = (plan, amount, date) => {

        if (plan !== "" && amount !== "" && date !== "") {
            let calculatedPrice = "";
            if (plan === "Embargo ISPs") {
                calculatedPrice = price_aio[amount][date];
            } else {
                calculatedPrice = price_santo[amount][date];
            }
            setPricePerProxy((calculatedPrice / amount).toFixed(2));
        }

    }

    const handleISPPurchase = () => {
        if (selectedRegion === "") {
            alert("Please select Region");
            return;
        }

        if (selectedAmount === "") {
            alert("Please select Amount");
            return;
        }

        if (selectedDate === "") {
            alert("Please select Date");
            return;
        }

        setLoadingISP(true);
        const accessToken = cookies.get('access_token');
        fetch('https://proxyapi.embargotickets.com/api/v1/createStaticOrder', {
            method: "POST",
            body: JSON.stringify({
                "discord_token": `${accessToken}`,
                "proxy_type": `${proxyType}`,
                "proxy_amount": `${selectedAmount}`,
                "proxy_region": `${selectedRegion}`,
                "proxy_time": `${selectedDate}`,
            })
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoadingISP(false);
                window.location.href = `${data["purchase_url"]}`;
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleBilling = () => {
        const accessToken = cookies.get('access_token');
        fetch('https://proxyapi.embargotickets.com/api/v1/createCustomerPortalSession', {
            method: "POST",
            body: JSON.stringify({
                "discord_token": `${accessToken}`
            })
        })
            .then(response => response.json())
            .then(data => {
                window.location.href = data["portal_session_url"];
            })
    }

    return (
        <div className={Styles.container}>
            <div className={Styles.brand}>
                {icon_items.purchase}
                <span>Purchase Plans</span>
            </div>

            <select
                id={"plan_static"}
                className={Styles.selectPlan}
                >
                <option value={"Embargo ISPs"}>Embargo ISPs</option>
            </select>

            <div className={Styles.lab}>
                <span>{selectedPlan}</span>

                <div className={Styles.labList}>
                    {selectedFeature.map((item, index) => (
                        <div className={Styles.labItem}>
                            <img src={checkCircle} alt="check-circle" />
                            <div className={`main-text ${Styles.moreItemText}`}>{item}</div>
                        </div>
                    ))}
                </div>

                <div className={Styles.from}>As low as</div>

                <div className={Styles.labInfo}>
                    <span>${fromPrice}/ISP</span>

                    <AnimationButtonGo
                        width="142px"
                        height="32px"
                        background="var(--re-white-button-bg)"
                        color="var(--re-white-button-color)"
                        size="13px"
                        borderRadius="10px"
                        button="dark"
                        label="Learn More"
                        goTo = "https://discord.gg/sJGYeb792n"
                        isNewTab = "_blank"
                    />

                </div>

                <img className={Styles.logo} src={Logo} />
            </div>

            <div className={Styles.amount}>
                <div className={Styles.title}>
                    {icon_items.data}

                    <span>Data Amount</span>
                </div>

                <select
                    className={Styles.selectPlan}
                    value={selectedRegion}
                    onChange={event => {
                        handleSelectRegion(event);
                    }}
                >
                    <option disabled selected hidden value=''>Region</option>
                    {Object.entries(RegionList).map(([key, value]) => (
                        <option value={key} key={key}>
                            {value}
                        </option>
                    ))}
                </select>

                <select
                    id={"amount"}
                    className={Styles.selectPlan}
                    value={selectedAmount}
                    onChange={event => {
                        handleSelectedAmount(event);
                    }}
                >
                    <option disabled selected hidden value=''>Amount</option>
                    {AmountList.map((item) => (
                        <option value={item}>{item} ISPs</option>
                    ))}
                </select>

                <div className={Styles.dataLabel}>
                    <span>Total</span>

                    <span>Price per Proxy</span>
                </div>

                <div className={Styles.price}>
                    <span>${calculatedPrice}</span>

                    <span>${pricePerProxy}</span>
                </div>

                <div className={Styles.button}>
                    <AnimationButton
                        width="calc((100% - 11px) / 2)"
                        height="42px"
                        background="var(--re-white-yellow-bg)"
                        color="#FFF"
                        size="16px"
                        borderRadius="12px"
                        button="yellow"
                        label= {loadingISP ? ('Loading...') : (
                           'Purchase'
                            )}
                        handler={handleISPPurchase}
                        />

                    <AnimationButton
                        width="calc((100% - 11px) / 2)"
                        height="42px"
                        background="var(--re-black-yellow-bg)"
                        color="var(--font-color)"
                        size="16px"
                        borderRadius="12px"
                        button="special"
                        label="Manage Renewal"
                        handler={handleBilling}
                    />
                </div>
            </div>
        </div>
    );
};

export default ISP;
