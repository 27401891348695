import { QueryClient } from "@tanstack/react-query";
// import { errorHandler } from "helpers";

// function queryErrorHandler(error) {
//   // error is type unknown because in js, anything can be an error (e.g. throw(5))
//   const title =
//     error instanceof Error ? error.message : "error connecting to server";

//   // prevent duplicate toasts
//   errorHandler(title);
// }

export function generateQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // onError: queryErrorHandler,
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // default cacheTime is 5 minutes; doesn't make sense for staleTime to exceed cacheTime
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
      },
      mutations: {
        // onError: queryErrorHandler,
      },
    },
  });
}

export const queryClient = generateQueryClient();
