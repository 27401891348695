import LocalStorage from "shared/utils/LocalStorage";
import axios from "./config";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "react-query/constants";

const getStoredUser = () => {
  const storedUser = localStorage.getItem(LocalStorage.User);
  return storedUser ? JSON.parse(storedUser) : null;
};

const setStoredUser = (user) => {
  localStorage.setItem(LocalStorage.User, JSON.stringify(user));
};

export const clearStoredUser = () => {
  localStorage.removeItem(LocalStorage.User);
};

export const getUser = async (token) => {
  if (!token) return;
  const data = await axios.get("/user", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

export const useUser = () => {
  const client = useQueryClient();
  const userData = JSON.parse(localStorage.getItem(LocalStorage.User));
  const token = userData?.authToken;
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.USER],
    queryFn: getUser.bind(null, token),
    initialData: getStoredUser,
    onSuccess: (received) => {
      if (!received) {
        clearStoredUser();
      } else {
        const user = received?.data?.data;
        if (user) {
          setStoredUser({ ...user, authToken: token });
          client.setQueryData([queryKeys.USER], user);
          return;
        }
        setStoredUser(received);
      }
    },
  });

  const logout = () => {
    client.setQueryData([queryKeys.USER], null);
    clearStoredUser();
  };

  return { data: data, isLoading, logout };
};
