import React from "react";
import Styles from "./SearchInput.module.scss";
import PropTypes from "prop-types";
import { icon_items } from "./icon-item";

const SearchInput = (props) => {
    const { background, value, onSelectChange, type, width } = props;

    return (
        <div className={Styles.searchOrder}>
            <input
                placeholder="Search Orders"
                onChange={onSelectChange}
                className={Styles.input}
                style={{backgroundColor: background, width: width}}
                type={type}
                value={value}
                min={0}
            />
            {type !== "number" && icon_items.searchIcon}   
        </div>        
    );
};

export default SearchInput;

SearchInput.propTypes = {
    background: PropTypes.string,
    value: PropTypes.array,
}