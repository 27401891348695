import React from "react";
import styles from "./RegisterLayout.module.scss";
import { Outlet } from "react-router-dom";
const RegisterLayout = () => {
  return (
    <main className={styles.content}>
      <Outlet />
    </main>
  );
};

export default RegisterLayout;
