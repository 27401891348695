import React from "react";
import PropTypes from "prop-types";
import styles from "./SideToggle.module.scss";

const SideToggle = (props) => {
  const { show, clicked, darkTheme } = props;
  return (
    <button
      className={`${styles.sideToggle} ${
        darkTheme ? styles.darkBg : styles.lightBg
      }`}
      onClick={clicked}
    >
      <div
        style={
          show ? { transform: "rotate(45deg)" } : { transform: "rotate(0)" }
        }
        className={show ? styles.showStripe : undefined}
      />
      <div
        style={
          show
            ? { transform: "translateX(20px)", opacity: "0" }
            : { transform: "translateX(0)", opacity: "1" }
        }
        className={show ? styles.showStripe : undefined}
      />
      <div
        style={
          show ? { transform: "rotate(-45deg)" } : { transform: "rotate(0)" }
        }
        className={show ? styles.showStripe : undefined}
      />
    </button>
  );
};
export default SideToggle;
SideToggle.propTypes = {
  show: PropTypes.bool,
  darkTheme: PropTypes.bool,
  clicked: PropTypes.func,
};
