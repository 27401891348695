export const queryKeys = {
  FAQ: "faq",
  PARTNERS: "partners",
  REVIEWS: "reviews",
  RESIDENTIAL: "residential",
  DC: "DC",
  ISP: "ISP",
  AIRESI: "AI-RESI",
  USER: "user",
  MEMBERSHIP_PRODUCT: "membershipProduct",
  USER_RESIDENTIAL: "userResidential",
  RESI_POOLS: "resiPools",
  RESI_PROXIES: "resiProxies",
  DC_PLAN: "dcPlan",
  ISP_PLAN: "ispPlan",
  USER_ISP: "userIsp",
  USER_DC: "userDc",
  ISP_STOCK: "ispProductStock",
  DC_STOCK: "dcProductStock",
  MEMBERSHIP_DATA: "membershipData",
  TOP_UP: "topUp",
};
