// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchInput_searchOrder__X9Npr {\n  width: 100%;\n  margin-top: 17px;\n  position: relative;\n}\n.SearchInput_searchOrder__X9Npr .SearchInput_input__VBGhX {\n  border-radius: 12px;\n  border: 1px solid transparent;\n  color: var(--font-dark-color);\n  font-family: Red Hat Display;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  padding: 11px 20px 10px 16px;\n}\n.SearchInput_searchOrder__X9Npr svg {\n  position: absolute;\n  right: 11px;\n  bottom: 6px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/SearchInput/SearchInput.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;EACA,6BAAA;EACA,6BAAA;EACA,4BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,4BAAA;AAEJ;AACE;EACE,kBAAA;EACA,WAAA;EACA,WAAA;AACJ","sourcesContent":[".searchOrder {\n  width: 100%;\n  margin-top: 17px;\n  position: relative; \n  .input {\n    border-radius: 12px;\n    border: 1px solid transparent;\n    color: var(--font-dark-color);\n    font-family: Red Hat Display;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n    padding: 11px 20px 10px 16px;\n  }\n\n  svg {\n    position: absolute;\n    right: 11px;\n    bottom: 6px;\n  } \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchOrder": "SearchInput_searchOrder__X9Npr",
	"input": "SearchInput_input__VBGhX"
};
export default ___CSS_LOADER_EXPORT___;
