import DashboardLayout from "components/Layouts/DashboardLayout";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ResiDashboard from "pages/ResiDashboard";
import StaticProxies from "pages/StaticProxies";
import Login from "pages/Login/Login";
import RegisterLayout from "components/Layouts/RegisterLayout/RegisterLayout";
import SelectMembership from "pages/SelectMembership";
import Cookies from 'universal-cookie';

const App = () => {

  const cookies = new Cookies();
  const pathname = useLocation();
  useEffect(() => {
    window.scroll({ top: 0 });
  }, [pathname]);   

  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path='/resi-dashboard' element={<ResiDashboard cookies={cookies} />} />
        <Route path='/static-proxies' element={<StaticProxies cookies={cookies} />} />
        <Route path='/membership' element={<SelectMembership cookies={cookies}/>} />
      </Route>

      <Route element={<RegisterLayout />}>
        <Route path='/' element={<Login cookies = {cookies} />} />
      </Route>
    </Routes>
  );
};

export default App;
