import axios from "axios";
import { clearStoredUser } from "./auth";

const apiUrl = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = apiUrl;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      clearStoredUser();
      window.location.href = "/resi-dashboard"; //  "/login"->"/purchases"
    } else if (error.response?.status === 404) {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axios;
