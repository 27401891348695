// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectInput_select__1mgUd {\n  width: 100%;\n  border-radius: 12px;\n  border: 1px solid transparent;\n  color: var(--font-dark-color);\n  font-family: Red Hat Display;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  padding: 11px 20px 10px 16px;\n  margin-top: 17px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/SelectInput/SelectInput.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,6BAAA;EACA,6BAAA;EACA,4BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,4BAAA;EACA,gBAAA;AACF","sourcesContent":[".select {\n  width: 100%;\n  border-radius: 12px;\n  border: 1px solid transparent;\n  color: var(--font-dark-color);\n  font-family: Red Hat Display;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  padding: 11px 20px 10px 16px;\n  margin-top: 17px;\n\n  option {\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "SelectInput_select__1mgUd"
};
export default ___CSS_LOADER_EXPORT___;
