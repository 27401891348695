import React from "react";
import styles from "./Modal.module.scss";
import ReactDOM from "react-dom";

const Modal = ({ children, show, variant, toggleHandler }) => {
  return ReactDOM.createPortal(
    <div style={{ position: "relative" }}>
      <div
        className={`${styles["popup-surface"]} ${
          show && styles.show
        } ${variant}`}
      >
        {children}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default Modal;
