import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import styles from "../Sidebar.module.scss";
import {connect} from "react-redux";
import Cookies from 'universal-cookie';

const SidebarItems = ({items, getHandler}) => {
    const {pathname} = useLocation();
    const [user, setUser] = useState("");
    const [userId, setUserId] = useState("");
    const [img, setImg] = useState("");

    const cookies = new Cookies();

    useEffect(() => {
        localStorage.setItem('theme', "light");
    }, []);

    useEffect(() => {
        const accessToken = cookies.get('access_token');
        const tokenType = cookies.get('token_type');

        fetch('https://discord.com/api/users/@me', {
            method: "GET",
            headers: {
                authorization: `${tokenType} ${accessToken}`,
            },
        })
            .then(result => {
                if (result.ok) {
                    return result.json();
                }
                cookies.remove('access_token');
                cookies.remove('token_type');
                window.location.replace('/');
                alert('Unauthorized access! Please log in.');
                throw new Error(`Request failed with status: ${result.status}`);
            })
            .then(response => {
                setUser(response.username);
                setUserId(response.id);
                setImg(response.avatar);
            })
            .catch(console.error);
    }, []);

    function handleLogout() {
        cookies.remove('access_token');
        cookies.remove('token_type');
        window.location.replace('/');
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.menu}>MENU</div>

            <ul className={styles.list}>
                {items?.map((item) => (
                    <li key={item?.text}>
                        <NavLink
                            to={item?.route}
                            className={`${styles.listItem} ${
                                pathname === item?.route ? styles.active : styles.notActive
                            }`}
                            onClick={() => getHandler(item?.text)}
                        >
                            <img src={item?.src} alt={item?.alt}/>
                            <span>{item?.text}</span>
                        </NavLink>
                    </li>
                ))}
            </ul>

            <div className={styles.option}>
                <div className={styles.setting}>
                    <div className={styles.info}>
                        <img src={`https://cdn.discordapp.com/avatars/${userId}/${img}.jpg`} alt="logo2"/>

                        <span>{user}</span>
                    </div>

                </div>

                <div className={styles.button}>
                    <NavLink onClick={handleLogout} className={styles.logout}>
                        Log Out
                    </NavLink>

                    <NavLink to="/" className={styles.support}>
                        Support
                    </NavLink>
                </div>
            </div>
        </div>
    );
};


export default SidebarItems;
