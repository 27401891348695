import React from 'react';
import loading from '../../assets/images/loading.gif';

function Loading(props) {

  return (
    <div id='loading'>
      <div className='loading-content'>
        <div className='waitMessage'>Please Wait</div>
        <div className='loadingImage'>
          <img src={loading} alt='loading' />
        </div>
      </div>
    </div>
  );
}

export default Loading;
