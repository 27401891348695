import React from "react";
import Styles from "./proxyList.module.scss";
import {icon_items} from "../../../pages/ResiDashboard/icon-item";
import AnimationButton from "../../UI/AnimationButton";
import Copy from "../../../assets/images/copy.svg";
import { successHandler } from "helpers";
import PropTypes from "prop-types";

const ProxyList = (props) => {
    const { proxyList } = props;

    const handleCopyOne = (e) => {
        var imgElement = e.target;
        var parentDiv = imgElement.parentNode.parentNode;
        var spanElement = parentDiv.querySelector('span');
        const proxyText = spanElement.innerHTML;
        navigator.clipboard.writeText(proxyText)
        successHandler("Successfully Copied!");
      };

    const handleCopyGen = () => {
        const proxyText = proxyList.join('\n');
            navigator.clipboard.writeText(proxyText)
            successHandler("Successfully Copied!");
    }; 

    const handleDownloadGen = () => {
        const proxyText = proxyList.join('\n');
        const blob = new Blob([proxyText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'proxies.txt';
        link.click();
        URL.revokeObjectURL(url);
      };

    return (
        <div className={`proxy-list ${Styles.proxy_container}`}>
            <div className={Styles.proxy_option}>
                {icon_items.list}

                <span>Generated Proxies</span>

                <AnimationButton
                    width="132px"
                    height="32px"
                    background="var(--re-white-button-bg)"
                    color="var(--re-white-button-color)"
                    size="13px"
                    borderRadius="10px"
                    button="dark"
                    label="Copy All"
                    handler={handleCopyGen}
                />

                <AnimationButton
                    width="142px"
                    height="32px"
                    background="linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%)"
                    color="var(--font-color)"
                    size="13px"
                    borderRadius="10px"
                    button="yellow"
                    label="Download"
                    handler={handleDownloadGen}
                />
            </div>

            <div className={Styles.proxy_list}>
                {proxyList.map((item, index) => (
                    <div className={Styles.proxy} key={index}>
                        <span>{item}</span>

                        <div>
                            <img src={Copy} alt="copy" onClick={handleCopyOne}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProxyList;
