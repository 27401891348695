import Logo from "components/UI/Logo";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import SidebarItems from "./SidebarItems";

const Sidebar = (props) => {
  return (
    <div className={`${styles.container}`}>
      <NavLink>
        <Logo darkTheme className={styles.logo} />
      </NavLink>

      <SidebarItems getHandler={props.handler} items = {props.menu} />
    </div>
  );
};

export default Sidebar;
