import React from "react";
import Styles from "./SelectInput.module.scss";
import PropTypes from "prop-types";

const SelectInput = (props) => {
    const { background, option, onSelectChange, onSelectChangeKind, onSelectChangePeriod, onSelectRegion, ref, isGB, isISP, value, isPeriod, isRegion, isGeneratePlan } = props;

    return (
        <select
            onChange={
                isISP ? onSelectChangeKind :(
                isPeriod ? onSelectChangePeriod :(
                isRegion ? onSelectRegion :
                onSelectChange))
            }
            className={Styles.select}
            style={{ backgroundColor: background }}
            ref={ref}
            value={value}
        >

            {option.map((item) => (
                !isGeneratePlan ? (<option
                    key={item.label}
                    style={{backgroundColor: background}}
                    value={item.label}
                >
                    {item.label} {isGB && "GB"} {isISP && "ISP"} {isPeriod && "days"}
                </option>) : (<option 
                    key={Object.keys(item)[0]} 
                    value={Object.keys(item)[0]}
                >
                {Object.keys(item)[0]}
                </option>)
            ))}
        </select>
    );
};

export default SelectInput;

SelectInput.propTypes = {
    background: PropTypes.string,
    option: PropTypes.array,
}