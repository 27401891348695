export const icon_items = {
    searchIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M17.5 18.75L23.75 25" stroke="url(#paint0_linear_16_3161)" stroke-width="2.5" stroke-linecap="round"/>
        <path d="M17.5 18.75L23.75 25" stroke="url(#paint1_linear_16_3161)" stroke-width="2.5" stroke-linecap="round"/>
        <path d="M17.5 18.75L23.75 25" stroke="url(#paint2_linear_16_3161)" stroke-width="2.5" stroke-linecap="round"/>
        <g filter="url(#filter0_bi_16_3161)">
          <circle cx="14.375" cy="14.375" r="9.375" fill="#F1F1F1" fill-opacity="0.1"/>
          <circle cx="14.375" cy="14.375" r="9.375" fill="url(#paint3_linear_16_3161)" fill-opacity="0.1"/>
          <circle cx="14.375" cy="14.375" r="9.25" stroke="url(#paint4_linear_16_3161)" stroke-opacity="0.1" stroke-width="0.25"/>
        </g>
        <g filter="url(#filter1_i_16_3161)">
          <circle cx="14.375" cy="14.375" r="5.625" fill="white" fill-opacity="0.16"/>
        </g>
        <defs>
          <filter id="filter0_bi_16_3161" x="2" y="2" width="24.75" height="24.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_16_3161"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_16_3161" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="0.5"/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_16_3161"/>
          </filter>
          <filter id="filter1_i_16_3161" x="8.75" y="8.75" width="11.25" height="11.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="0.5"/>
            <feGaussianBlur stdDeviation="0.25"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_16_3161"/>
          </filter>
          <linearGradient id="paint0_linear_16_3161" x1="17.7604" y1="20" x2="24.5734" y2="20.9463" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FE82DB"/>
            <stop offset="1" stop-color="#68E4FF"/>
          </linearGradient>
          <linearGradient id="paint1_linear_16_3161" x1="17.5" y1="21.8771" x2="23.75" y2="21.8771" gradientUnits="userSpaceOnUse">
            <stop stop-color="#18C6EB"/>
            <stop offset="1" stop-color="#0A91B3"/>
          </linearGradient>
          <linearGradient id="paint2_linear_16_3161" x1="23.3667" y1="24.8267" x2="16.7616" y2="17.5678" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFA329"/>
            <stop offset="0.3574" stop-color="#FE7B1C"/>
            <stop offset="1" stop-color="#FC2A01"/>
          </linearGradient>
          <linearGradient id="paint3_linear_16_3161" x1="5" y1="14.3812" x2="23.75" y2="14.3812" gradientUnits="userSpaceOnUse">
            <stop stop-color="#18C6EB"/>
            <stop offset="1" stop-color="#0A91B3"/>
          </linearGradient>
          <linearGradient id="paint4_linear_16_3161" x1="5.58594" y1="5.85227" x2="23.75" y2="5.85227" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FD84DC"/>
            <stop offset="1" stop-color="#7FD6FA"/>
          </linearGradient>
        </defs>
        </svg>
    ),
}