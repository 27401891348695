import React from 'react';
import { icon_items } from "../../../pages/ResiDashboard/icon-item";
import PropTypes from "prop-types";

const AnimationButton = (props) => {
  const {width, height, background, color, size, borderRadius, label, button, handler} = props;
  return (
          <button
              className='animation-btn'
              style={{
                  width: width,
                  height: height,
                  background: background,
                  color: color,
                  fontSize: size,
                  borderRadius: borderRadius,
              }}
              onClick={handler}
          >
              {label}

              {button === "light" ? icon_items.light_arrow :
                  button === "dark" ? icon_items.dark_arrow :
                      button === "yellow" ? icon_items.yellow_arrow :
                          button === "special" ? icon_items.special_arrow :
                            icon_items.selected}
          </button>
  );
}

export default AnimationButton;

AnimationButton.prototype = {
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    borderRadius: PropTypes.string,
    label: PropTypes.string,
    button: PropTypes.string,
    handler: PropTypes.func
}