import React from "react";
import PropTypes from "prop-types";
import styles from "./SideDrawer.module.scss";
import Logo from "components/UI/Logo";
import { NavLink } from "react-router-dom";
import SidebarItems from "../Sidebar/SidebarItems";

const SideDrawer = (props) => {
  const { show, closeClicked } = props;

  return (
    <div
      className={`${styles.sideDrawer} ${show ? styles.open : styles.close}`}
    >
      <NavLink to='/' onClick={closeClicked}>
        <Logo darkTheme />
      </NavLink>

      <div className={styles.navItems}>
        <SidebarItems getHandler={props.handler} items = {props.menu} />

      </div>
    </div>
  );
};
export default SideDrawer;
SideDrawer.propTypes = {
  show: PropTypes.bool,
  closeClicked: PropTypes.func,
  toggleRegisterHandler: PropTypes.func,
};
